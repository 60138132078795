import React from "react"
import Layout from "../components/layout"
import profileImage from "../../content/home/kazim-yardimci.jpg"
import {GatsbySeo} from 'gatsby-plugin-next-seo/src/meta/gatsby-seo';
import useSiteMetadata from '../hooks/use-site-metadata';

const IndexPage = ({location}) => {
    const { siteUrl, defaultImage } = useSiteMetadata();

    return (
        <Layout>
            <GatsbySeo
                title="Varlıktan Veriler"
                titleTemplate="%s - Dinin Özü Tasavvuf"
                description="Kazım Yardımcı'nın kitapları ve diğer yayınları hakkında bilgi edinebilir, bu yayınlara erişebilirsiniz. Dinin Özü Tasavvuf. Bu sistem herkese uygun."
                openGraph={{
                    type: "website",
                    url: `${siteUrl}${location.pathname}`,
                    images: [
                        {
                            url: `${siteUrl}${defaultImage}`,
                            width: 1200,
                            height: 630
                        }
                    ]
                }}
            />
            <section id="content">
                <div className="content-wrap pt-0">
                    <div className="container clearfix topmargin-sm">
                        <div className="row gutter-50">
                            <div className="col-md-8">
                                <div className="heading-block center">
                                    <h3>Amacımız</h3>
                                </div>

                                <p className="text-larger center">Dinin özü olan tarihi Tasavvuf gerçeğini, tamamen Hak'kın rızası için, bu çağın ilmiyle bağdaştırarak insan sevgisi adına insanlığa yaymaktır. Çünkü gerçekte insanın adı Hazret-i İnsan'dır.</p>
                            </div>


                            <div className="col-md-4">
                                <img className="shadow-sm" src={profileImage} alt="Kâzım YARDIMCI"/>
                            </div>

                        </div>


                    </div>

                    <div className="container clearfix topmargin-sm bottommargin-lg">
                        <div className="fancy-title title-bottom-border">
                            <h4>Dinin Özü Tasavvuf</h4>
                        </div>

                        <p>Bilindiği gibi her bilgin dini kendine göre yorumlamıştır. Mesele, bu yorumların dinin özüne en uygun olanını seçebilmektir.Tasavvufi Din yorumu İslâm’a en uygun olanıdır. Çünkü Tasavvuf: Dinin özünün öğretisidir.Kabukla - dışla, şartla - şeriatla yani teferruatla uğraşmaz. Tasavvuf;
                            Allah, Doğa ve İnsanı ve bunların gerçeğini öğretmeye çalışır.</p>
                        <p>Doğadaki güzellikler, düzen ve hikmet Allah’ın doğaya yansımasıdır.Doğa, Tanrının eseridir. İnsan ise en büyük eseridir. Çünkü insan ten ve ruhtan (Akıldan) oluşmuştur. Dış ve iç Âlemin bileşimi (sentezi) olduğundan tüm âlemlerden üstündür. Tasavvufçu, işte bu doğadaki bilgiyi,
                            düzeni, hikmeti ve güzelliği yaşamaya, insandaki fiziki ve ruhi güzelliği görmeye ve insanın hakikatini öğrenmeye çalışır. “Kendini bilen Tanrıyı bilir”. Kısaca, Tasavvuf Marifet, Hikmet (Bilgi) ve Aşktan, Sevgiden ibarettir.</p>
                        <p>Tasavvuf: Allah’ı, insanın ve doğanın özü bilir. Bu ise; sonsuz İlim ve Hikmet (Bilgi) ve sonsuz Sevgi ve Aşktır. Vücud-u Mutlak’ı bilmek, Hüsn-ü Mutlak’a aşık olmaktır. Tasavvuf, bunun dışındaki dini Şeriat ve Fıkıh bilgileri ile uğraşmaz. İbadet edecek kadar ilmihal bilgisinden
                            ötesi Tasavvufçuyu alakadar etmez. Şeriat, Tarikat onun için amaç değil ayrıntılardır. Tali araçlardır. Asıl olan değil, Füru’dur. (teferruattır, simgelerdir, şekildir) “Yere göğe sığmayan, sonsuz bilgi ve güç (enerji) olan Allah’ı insanda ve kendi kalbinde arar Tasavvufçu”.</p>
                        <p>Yine tasavvufcu; Tefekkür (Düşünce) ile marifet-hikmet ve hakikatı öğrenmeye, insandaki ve doğadaki güzellikler ile de İlahi Cemali -Hüsn-ü Mutlakı sevmeye, yani mecazi sevgiden, hakiki sevgiye kavuşmak ister. Bunun sonucu ise: Güzel ahlâk ve dürüstlüktür. Toplumun çıkarını kendi
                            çıkarından üstün tutmak ve riyadan tamamen soyutlanıp gerçekçi olmaktır. Kavgayı, tartışmayı, fitneyi, fesadı terk edip, başkaları ile değil kendi nefsi, heva ve hevesi ile cihad yapmaktır. Merhametli ve cömert olup, dünya yaşamında az ile mutlu olmaktır. Şiddete karşı olmaktır.</p>
                        <p>Gerçek Tasavvufçu şekil ve kisve Müslümanlığına karşıdır. Medrese, tekke ve mescide önem vermez. Hakikati kendinde bulur, Allah’ı kalbinde arar. Onun medresesi, tekkesi ve mescidi kendi kalbidir. Önemi her zaman kalbe verir. Çünkü gerçek tekke ve mescid kalptir. Kalp içinse en önemli
                            olan Allah’ın Zikri ve sevgisidir. (Kalbin Zikri ve Kalbin Sevgisidir) Zahiri bir zikir ve cismani bir sevgi değildir. Tefekkür(düşünce), Tezekkür (Rabbi hatırda tutmak ve asla unutmamak, en önemlisi de Tanrı’yı ve O’nun büyük ve gizemli olan eseri İnsan-ı Kâmil’i, Ademi ve
                            çocuklarını ve doğayı sevmektir. Ham iken pişkin insan olmak ve Kemale ulaşmaktır. Bütün Tasavvufçular bunu böyle izah etmiştir.</p>
                        <p>İlahi mesaj Kur’an-ı Kerim, ilahi Mesajcımız Hz. Muhammed’in Sünnet ve Hadisleri, Hz. Ali ve Hz. Ebubekir’in ve onları izleyen tüm Tasavvufçuların öğretisi üzere bu fakir, Tasavvuf konusunda Varlık, İslâm’da Mezhepler ve Yükseliş, Muhammed-İsa-Adem isimli ve diğer yazmış olduğumuz
                            kitaplarda Tasavvufu çok ayrıntılı bir şekilde işlemiştir. Yunus Emre ve Seyyid Ahmed er Rufai Hazretleri ise bir deniz olan Tasavvufu şu şekilde özetlemişlerdir.</p>
                        <p>Yunus:</p>
                        <blockquote>
                            <p>"Şeriat, Tarikat yoldur varana,<br/>
                                Marifet, Hakikat ondan içeru”</p>
                        </blockquote>

                        <p>Seyyid Ahmed er Rufai ise:</p>
                        <blockquote>
                            <p>"Tasavvufçunun kalbi sevgi ve aşkla nurludur,<br/>
                                Gönlü ise Marifet nuru ile geniştir (Göğsü dar değildir)” demişlerdir.</p>
                        </blockquote>

                        <p>Biz bu iki görüşe inanıyoruz ve bulmaya çalışıyoruz.</p>
                        <p>Mutasavvıf Şair Neyzen Tevfik bakın bu konuda ne söylüyor:</p>
                        <blockquote><p>"Müslümanlıkta tasavvuf geriyor cehle göğüs<br/>
                            Rafizi, alevi, Sünni bunları unut, hepsine küs.<br/>
                            Mekke, Medine, Kerbela, Kudüs<br/>
                            Bunların sureti zahirdeki alayişi süs.*<br/>
                            Mescid, medrese, manastır, kilise VATİKANDAN bana ne."</p></blockquote>
                        <p>* Şair Neyzen Tevfik "Sureti zahirdeki alayişi süs" demekle, bu ritüellerin, bu gösterilerin zahiri anlamı süstür, diyor Ama bu mekânların batini-gerçek anlamına saygılı olduğunu belirtiyor.</p>
                        <p>Kaynak: "Yunus Divanı”, Seyyid Ahmed er Rufai Hazretlerinin “Onların Âlemi” isimli kitabı, 25. Hadis</p>
                        <p className="float-right">Kâzım YARDIMCI</p>
                    </div>

                </div>
            </section>
        </Layout>
    )
}

export default IndexPage
